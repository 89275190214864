import './footer.scss'

function Footer() {
  return (
    <div className="footer-text">
      <p>© Tatiana Youssoufa Saidou 2024</p>
    </div>
  )
}

export default Footer
